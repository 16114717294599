import React, { useState, useEffect } from 'react';
import { Flag } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import api from '../api';
import moment from 'moment';

export default function UpcomingMatch() {
  const location = useLocation();
  const [games, setGames] = useState([]);
  const [isGamesEmpty, setIsGamesEmpty] = useState(true); // Boolean to check if games is empty

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await api.get('/games/upcoming');
        setGames(response.data);
        setIsGamesEmpty(response.data.length === 0); // Update the boolean based on the data length
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, []);

  return (

    <div className='text-white px-1'>
      <div className='flex justify-center pt-2 text-2xl'>Proximos Jogos</div>
      <div className='overflow-y-auto max-h-72'>
        {/* Conditional rendering */}
        {!isGamesEmpty ? (
          games.map((game, index) => {
            const player1 = game.Hands[0].User1;
            const player2 = game.Hands[0].User2;
            return (
              <div
                key={index}
                className='flex flex-col text-white gap-1 font-bold m-2 justify-center text-center items-center'
              >
                <div className='flex flex-row justify-center py-1 gap-3'>
                  <div className='flex flex-col m-1'>
                    <div
                      key={player1.id}
                      className='text-sm text-start overflow-hidden text-ellipsis whitespace-nowrap w-40'
                    >
                      <Flag name={player1.Nacionality} /> {player1.name}
                    </div>
                    <div
                      key={player2.id}
                      className='text-sm text-start overflow-hidden text-ellipsis whitespace-nowrap w-40'
                    >
                      <Flag name={player2.Nacionality} /> {player2.name}
                    </div>
                  </div>
                  <div className='flex-col text-md text-center justify-center items-center h-full py-2'>
                    {moment(game.Date).format('H:mm')}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className='flex h-64 justify-center items-center text-white'>Sem próximos jogos.</div>
        )}

      </div>
    </div>

  );
}
