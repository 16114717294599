function Footer() {
    return (
        <div className="absolute bottom-0 flex justify-center items-center bg-neutral-800 h-24 mt-24 p-1 w-full">
            <div className="h-full p-2 bg-neutral-800 z-50" style={{ width: "1005px" }}>
                <div className="flex flex-row gap-3 items-center justify-center m-1">
                    <div className="w-auto px-2 pt-1.5 h-10 bg-neutral-800 hover:underline underline-offset-4 cursor-pointer select-none text-white sm:text-sm text-xs rounded-lg text-center justify-center">Termos</div>
                    <div className="w-auto px-2 pt-1.5 h-10 bg-neutral-800 hover:underline underline-offset-4 cursor-pointer select-none text-white sm:text-sm text-xs rounded-lg text-center justify-center">Privacidade</div>
                    <div className="w-auto px-2 pt-1.5 h-10 bg-neutral-800 hover:underline underline-offset-4 cursor-pointer select-none text-white sm:text-sm text-xs rounded-lg text-center justify-center">Copyright (DMCA)</div>
                    <div className="w-auto px-2 pt-1.5 h-10 bg-neutral-800 hover:underline underline-offset-4 cursor-pointer select-none text-white sm:text-sm text-xs rounded-lg text-center justify-center">Server Status</div>
                    <div className="w-auto px-2 pt-1.5 h-10 bg-neutral-800 hover:underline underline-offset-4 cursor-pointer select-none text-white sm:text-sm text-xs rounded-lg text-center justify-center">Source Code</div>
                </div>
                <div className="flex flex-row p-1.5 gap-3 items-center justify-center">
                    <div className="w-auto px-2 h-5 text-neutral-400 text-xs rounded-lg text-center  select-none justify-center">powered by <span className="hover:underline underline-offset-4 cursor-pointer text-violet-600"><span className="text-violet-400">Purple</span> <span className="text-violet-600">Profile</span></span></div>
                </div>
            </div>
        </div>
    );
}

export default Footer;