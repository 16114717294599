import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../features/auth/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const actionResult = await dispatch(loginUser({ email, password }));

      if (loginUser.fulfilled.match(actionResult)) {
        toast.success('Login efetuado com sucesso.');
        navigate('/');
      } else {
        toast.error(actionResult.payload.error || 'Erro desconhecido ao efetuar o login');
      }
    } catch (error) {
      toast.error('Erro ao efetuar o login: ' + error.message);
    }
  };

  const location = useLocation();

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/Login':
        return 'border-indigo-400';
      case '/':
        return 'border-yellow-500';
      default:
        return 'border-gray-300';
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-transparent'>
      <div
        className={`flex flex-col ${getCurrentBorderColor()} border-2 w-96 bg-neutral-900 p-5 rounded-xl justify-center`}
      >
        <div>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-white'>Login</h2>
        </div>
        <form onSubmit={handleSubmit} className='mt-4 space-y-4' action='#' method='POST'>
          <input type='hidden' name='remember' value='true' />
          <div className='rounded-md shadow-sm -space-y-px'>
            <div>
              <label htmlFor='email-address' className='sr-only'>
                Email address
              </label>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:z-10 sm:text-sm'
                placeholder='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor='password' className='sr-only'>
                Password
              </label>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:z-10 sm:text-sm'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type='submit'
              className={`group ${getCurrentBorderColor()} border-2 relative w-full flex justify-center py-2 px-4 border-transparent text-md font-bold rounded-md text-black bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 focus:outline-none`}
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
