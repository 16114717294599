import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Flag } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import api from '../api';

const RecentMatchBox = () => {
  const { userid } = useParams();
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get(`/user/details/${userid}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userid]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} | ${formattedTime}`;
  };

  const isGameFinished = (game) => {
    return game.P1P === 4 || game.P2P === 4;
  };

  if (isLoading) {
    return <div className='text-white'>Loading...</div>;
  }

  if (error) {
    return <div className='text-white'>Error: {error}</div>;
  }

  if (!user || !user.Games || user.Games.length === 0) {
    return <div className=' text-white'>No recent games found.</div>;
  }

  const finishedGames = user.Games.filter((game) => isGameFinished(game));

  return (
    <div className='flex h-full w-full'>
      <div className='flex flex-1 w-full relative flex-col gap-6 p-5'>
        <div className='flex items-center gap-3'>
          <Icon icon='icon-park-solid:game-emoji' width='32' height='32' color='white' />
          <span className='text-lg text-white'>Jogos Recentes</span>
        </div>
        <div className='grid gap-8 grid-cols-3 w-full overflow-y-auto'>
          {finishedGames.length > 0 ? (
            finishedGames.map((game, index) => {
              const hand = game.Hands.find(
                (hand) =>
                  (hand.User1 && hand.User1.id === parseInt(userid)) ||
                  (hand.User2 && hand.User2.id === parseInt(userid))
              );
              const player1 = hand?.User1;
              const player2 = hand?.User2;
              return (
                <React.Fragment key={index}>
                  {/* PLAYER 1 */}
                  <div className='row-span-1 col-span-1 text-white pl-2'>
                    {player1 && (
                      <div className='flex flex-row items-center gap-5'>
                        <img
                          className='w-16 h-16 rounded-full object-cover'
                          src={`https://api.ligas.purplews.live/photos/` + player1.Photo}
                          alt={player1.name}
                        />
                        <div className='flex flex-col text-start gap-1'>
                          <span>{player1.name}</span>
                          <span>
                            <Flag name={player1.Nacionality} />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* INFO */}
                  <div className='row-span-1 col-span-1 text-white'>
                    <div className='flex flex-col items-center text-xs gap-1 justify-center'>
                      <span>{game.League_name}</span>
                      <span className='text-xl'>
                        {game.P1P} - {game.P2P}
                      </span>
                      {!isExtraSmallScreen && (
                        <>
                          <span className='text-base'>{formatDate(game.Date)}</span>
                        </>
                      )}
                    </div>
                  </div>

                  {/* PLAYER 2 */}
                  <div className='row-span-1 col-span-1 text-white pr-2'>
                    {player2 && (
                      <div className='flex flex-row items-center gap-5 justify-end'>
                        <div className='flex flex-col text-end gap-1'>
                          <span>{player2.name}</span>
                          <span>
                            <Flag name={player2.Nacionality} />
                          </span>
                        </div>
                        <img
                          className='w-16 h-16 rounded-full object-cover'
                          src={`https://api.ligas.purplews.live/photos/` + player2.Photo}
                          alt={player2.name}
                        />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <div className='text-white'>No recent games found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentMatchBox;
