import { Flag } from 'semantic-ui-react';

const countryOptions = [
  {
    key: 'pt',
    value: 'portugal',
    text: (
      <>
        <Flag name='portugal' />
        Portugal
      </>
    ),
  },
  {
    key: 'af',
    value: 'afghanistan',
    text: (
      <>
        <Flag name='afghanistan' />
        Afghanistan
      </>
    ),
  },
  {
    key: 'ax',
    value: 'aland islands',
    text: (
      <>
        <Flag name='aland islands' />
        Aland Islands
      </>
    ),
  },
  {
    key: 'al',
    value: 'albania',
    text: (
      <>
        <Flag name='albania' />
        Albania
      </>
    ),
  },
  {
    key: 'dz',
    value: 'algeria',
    text: (
      <>
        <Flag name='algeria' />
        Algeria
      </>
    ),
  },
  {
    key: 'as',
    value: 'american samoa',
    text: (
      <>
        <Flag name='american samoa' />
        American Samoa
      </>
    ),
  },
  {
    key: 'ad',
    value: 'andorra',
    text: (
      <>
        <Flag name='andorra' />
        Andorra
      </>
    ),
  },
  {
    key: 'ao',
    value: 'angola',
    text: (
      <>
        <Flag name='angola' />
        Angola
      </>
    ),
  },
  {
    key: 'ai',
    value: 'anguilla',
    text: (
      <>
        <Flag name='anguilla' />
        Anguilla
      </>
    ),
  },
  {
    key: 'ag',
    value: 'antigua',
    text: (
      <>
        <Flag name='antigua' />
        Antigua
      </>
    ),
  },
  {
    key: 'ar',
    value: 'argentina',
    text: (
      <>
        <Flag name='argentina' />
        Argentina
      </>
    ),
  },
  {
    key: 'am',
    value: 'armenia',
    text: (
      <>
        <Flag name='armenia' />
        Armenia
      </>
    ),
  },
  {
    key: 'aw',
    value: 'aruba',
    text: (
      <>
        <Flag name='aruba' />
        Aruba
      </>
    ),
  },
  {
    key: 'au',
    value: 'australia',
    text: (
      <>
        <Flag name='australia' />
        Australia
      </>
    ),
  },
  {
    key: 'at',
    value: 'austria',
    text: (
      <>
        <Flag name='austria' />
        Austria
      </>
    ),
  },
  {
    key: 'az',
    value: 'azerbaijan',
    text: (
      <>
        <Flag name='azerbaijan' />
        Azerbaijan
      </>
    ),
  },
  {
    key: 'bs',
    value: 'bahamas',
    text: (
      <>
        <Flag name='bahamas' />
        Bahamas
      </>
    ),
  },
  {
    key: 'bh',
    value: 'bahrain',
    text: (
      <>
        <Flag name='bahrain' />
        Bahrain
      </>
    ),
  },
  {
    key: 'bd',
    value: 'bangladesh',
    text: (
      <>
        <Flag name='bangladesh' />
        Bangladesh
      </>
    ),
  },
  {
    key: 'bb',
    value: 'barbados',
    text: (
      <>
        <Flag name='barbados' />
        Barbados
      </>
    ),
  },
  {
    key: 'by',
    value: 'belarus',
    text: (
      <>
        <Flag name='belarus' />
        Belarus
      </>
    ),
  },
  {
    key: 'be',
    value: 'belgium',
    text: (
      <>
        <Flag name='belgium' />
        Belgium
      </>
    ),
  },
  {
    key: 'bz',
    value: 'belize',
    text: (
      <>
        <Flag name='belize' />
        Belize
      </>
    ),
  },
  {
    key: 'bj',
    value: 'benin',
    text: (
      <>
        <Flag name='benin' />
        Benin
      </>
    ),
  },
  {
    key: 'bm',
    value: 'bermuda',
    text: (
      <>
        <Flag name='bermuda' />
        Bermuda
      </>
    ),
  },
  {
    key: 'bt',
    value: 'bhutan',
    text: (
      <>
        <Flag name='bhutan' />
        Bhutan
      </>
    ),
  },
  {
    key: 'bo',
    value: 'bolivia',
    text: (
      <>
        <Flag name='bolivia' />
        Bolivia
      </>
    ),
  },
  {
    key: 'ba',
    value: 'bosnia',
    text: (
      <>
        <Flag name='bosnia' />
        Bosnia
      </>
    ),
  },
  {
    key: 'bw',
    value: 'botswana',
    text: (
      <>
        <Flag name='botswana' />
        Botswana
      </>
    ),
  },
  {
    key: 'bv',
    value: 'bouvet island',
    text: (
      <>
        <Flag name='bouvet island' />
        Bouvet Island
      </>
    ),
  },
  {
    key: 'br',
    value: 'brazil',
    text: (
      <>
        <Flag name='brazil' />
        Brazil
      </>
    ),
  },
  {
    key: 'vg',
    value: 'british virgin islands',
    text: (
      <>
        <Flag name='british virgin islands' />
        British Virgin Islands
      </>
    ),
  },
  {
    key: 'bn',
    value: 'brunei',
    text: (
      <>
        <Flag name='brunei' />
        Brunei
      </>
    ),
  },
  {
    key: 'bg',
    value: 'bulgaria',
    text: (
      <>
        <Flag name='bulgaria' />
        Bulgaria
      </>
    ),
  },
  {
    key: 'bf',
    value: 'burkina faso',
    text: (
      <>
        <Flag name='burkina faso' />
        Burkina Faso
      </>
    ),
  },
  {
    key: 'mm',
    value: 'burma (myanmar)',
    text: (
      <>
        <Flag name='burma (myanmar)' />
        Burma (Myanmar)
      </>
    ),
  },
  {
    key: 'bi',
    value: 'burundi',
    text: (
      <>
        <Flag name='burundi' />
        Burundi
      </>
    ),
  },
  {
    key: 'tc',
    value: 'caicos islands',
    text: (
      <>
        <Flag name='caicos islands' />
        Caicos Islands
      </>
    ),
  },
  {
    key: 'kh',
    value: 'cambodia',
    text: (
      <>
        <Flag name='cambodia' />
        Cambodia
      </>
    ),
  },
  {
    key: 'cm',
    value: 'cameroon',
    text: (
      <>
        <Flag name='cameroon' />
        Cameroon
      </>
    ),
  },
  {
    key: 'ca',
    value: 'canada',
    text: (
      <>
        <Flag name='canada' />
        Canada
      </>
    ),
  },
  {
    key: 'cv',
    value: 'cape verde',
    text: (
      <>
        <Flag name='cape verde' />
        Cape Verde
      </>
    ),
  },
  {
    key: 'ky',
    value: 'cayman islands',
    text: (
      <>
        <Flag name='cayman islands' />
        Cayman Islands
      </>
    ),
  },
  {
    key: 'cf',
    value: 'central african republic',
    text: (
      <>
        <Flag name='central african republic' />
        Central African Republic
      </>
    ),
  },
  {
    key: 'td',
    value: 'chad',
    text: (
      <>
        <Flag name='chad' />
        Chad
      </>
    ),
  },
  {
    key: 'cl',
    value: 'chile',
    text: (
      <>
        <Flag name='chile' />
        Chile
      </>
    ),
  },
  {
    key: 'cn',
    value: 'china',
    text: (
      <>
        <Flag name='china' />
        China
      </>
    ),
  },
  {
    key: 'cx',
    value: 'christmas island',
    text: (
      <>
        <Flag name='christmas island' />
        Christmas Island
      </>
    ),
  },
  {
    key: 'cc',
    value: 'cocos islands',
    text: (
      <>
        <Flag name='cocos islands' />
        Cocos Islands
      </>
    ),
  },
  {
    key: 'co',
    value: 'colombia',
    text: (
      <>
        <Flag name='colombia' />
        Colombia
      </>
    ),
  },
  {
    key: 'km',
    value: 'comoros',
    text: (
      <>
        <Flag name='comoros' />
        Comoros
      </>
    ),
  },
  {
    key: 'cg',
    value: 'congo',
    text: (
      <>
        <Flag name='congo' />
        Congo
      </>
    ),
  },
  {
    key: 'cd',
    value: 'congo brazzaville',
    text: (
      <>
        <Flag name='congo brazzaville' />
        Congo Brazzaville
      </>
    ),
  },
  {
    key: 'ck',
    value: 'cook islands',
    text: (
      <>
        <Flag name='cook islands' />
        Cook Islands
      </>
    ),
  },
  {
    key: 'cr',
    value: 'costa rica',
    text: (
      <>
        <Flag name='costa rica' />
        Costa Rica
      </>
    ),
  },
  {
    key: 'ci',
    value: 'cote divoire',
    text: (
      <>
        <Flag name='cote divoire' />
        Cote Divoire
      </>
    ),
  },
  {
    key: 'hr',
    value: 'croatia',
    text: (
      <>
        <Flag name='croatia' />
        Croatia
      </>
    ),
  },
  {
    key: 'cu',
    value: 'cuba',
    text: (
      <>
        <Flag name='cuba' />
        Cuba
      </>
    ),
  },
  {
    key: 'cy',
    value: 'cyprus',
    text: (
      <>
        <Flag name='cyprus' />
        Cyprus
      </>
    ),
  },
  {
    key: 'cz',
    value: 'czech republic',
    text: (
      <>
        <Flag name='czech republic' />
        Czech Republic
      </>
    ),
  },
  {
    key: 'dk',
    value: 'denmark',
    text: (
      <>
        <Flag name='denmark' />
        Denmark
      </>
    ),
  },
  {
    key: 'dj',
    value: 'djibouti',
    text: (
      <>
        <Flag name='djibouti' />
        Djibouti
      </>
    ),
  },
  {
    key: 'dm',
    value: 'dominica',
    text: (
      <>
        <Flag name='dominica' />
        Dominica
      </>
    ),
  },
  {
    key: 'do',
    value: 'dominican republic',
    text: (
      <>
        <Flag name='dominican republic' />
        Dominican Republic
      </>
    ),
  },
  {
    key: 'ec',
    value: 'ecuador',
    text: (
      <>
        <Flag name='ecuador' />
        Ecuador
      </>
    ),
  },
  {
    key: 'eg',
    value: 'egypt',
    text: (
      <>
        <Flag name='egypt' />
        Egypt
      </>
    ),
  },
  {
    key: 'sv',
    value: 'el salvador',
    text: (
      <>
        <Flag name='el salvador' />
        El Salvador
      </>
    ),
  },
  {
    key: 'gq',
    value: 'equatorial guinea',
    text: (
      <>
        <Flag name='equatorial guinea' />
        Equatorial Guinea
      </>
    ),
  },
  {
    key: 'er',
    value: 'eritrea',
    text: (
      <>
        <Flag name='eritrea' />
        Eritrea
      </>
    ),
  },
  {
    key: 'ee',
    value: 'estonia',
    text: (
      <>
        <Flag name='estonia' />
        Estonia
      </>
    ),
  },
  {
    key: 'et',
    value: 'ethiopia',
    text: (
      <>
        <Flag name='ethiopia' />
        Ethiopia
      </>
    ),
  },
  {
    key: 'eu',
    value: 'european union',
    text: (
      <>
        <Flag name='european union' />
        European Union
      </>
    ),
  },
  {
    key: 'fk',
    value: 'falkland islands',
    text: (
      <>
        <Flag name='falkland islands' />
        Falkland Islands
      </>
    ),
  },
  {
    key: 'fo',
    value: 'faroe islands',
    text: (
      <>
        <Flag name='faroe islands' />
        Faroe Islands
      </>
    ),
  },
  {
    key: 'fj',
    value: 'fiji',
    text: (
      <>
        <Flag name='fiji' />
        Fiji
      </>
    ),
  },
  {
    key: 'fi',
    value: 'finland',
    text: (
      <>
        <Flag name='finland' />
        Finland
      </>
    ),
  },
  {
    key: 'fr',
    value: 'france',
    text: (
      <>
        <Flag name='france' />
        France
      </>
    ),
  },
  {
    key: 'gf',
    value: 'french guiana',
    text: (
      <>
        <Flag name='french guiana' />
        French Guiana
      </>
    ),
  },
  {
    key: 'pf',
    value: 'french polynesia',
    text: (
      <>
        <Flag name='french polynesia' />
        French Polynesia
      </>
    ),
  },
  {
    key: 'tf',
    value: 'french territories',
    text: (
      <>
        <Flag name='french territories' />
        French Territories
      </>
    ),
  },
  {
    key: 'ga',
    value: 'gabon',
    text: (
      <>
        <Flag name='gabon' />
        Gabon
      </>
    ),
  },
  {
    key: 'gm',
    value: 'gambia',
    text: (
      <>
        <Flag name='gambia' />
        Gambia
      </>
    ),
  },
  {
    key: 'ge',
    value: 'georgia',
    text: (
      <>
        <Flag name='georgia' />
        Georgia
      </>
    ),
  },
  {
    key: 'de',
    value: 'germany',
    text: (
      <>
        <Flag name='germany' />
        Germany
      </>
    ),
  },
  {
    key: 'gh',
    value: 'ghana',
    text: (
      <>
        <Flag name='ghana' />
        Ghana
      </>
    ),
  },
  {
    key: 'gi',
    value: 'gibraltar',
    text: (
      <>
        <Flag name='gibraltar' />
        Gibraltar
      </>
    ),
  },
  {
    key: 'gr',
    value: 'greece',
    text: (
      <>
        <Flag name='greece' />
        Greece
      </>
    ),
  },
  {
    key: 'gl',
    value: 'greenland',
    text: (
      <>
        <Flag name='greenland' />
        Greenland
      </>
    ),
  },
  {
    key: 'gd',
    value: 'grenada',
    text: (
      <>
        <Flag name='grenada' />
        Grenada
      </>
    ),
  },
  {
    key: 'gp',
    value: 'guadeloupe',
    text: (
      <>
        <Flag name='guadeloupe' />
        Guadeloupe
      </>
    ),
  },
  {
    key: 'gu',
    value: 'guam',
    text: (
      <>
        <Flag name='guam' />
        Guam
      </>
    ),
  },
  {
    key: 'gt',
    value: 'guatemala',
    text: (
      <>
        <Flag name='guatemala' />
        Guatemala
      </>
    ),
  },
  {
    key: 'gw',
    value: 'guinea-bissau',
    text: (
      <>
        <Flag name='guinea-bissau' />
        Guinea-Bissau
      </>
    ),
  },
  {
    key: 'gn',
    value: 'guinea',
    text: (
      <>
        <Flag name='guinea' />
        Guinea
      </>
    ),
  },
  {
    key: 'gy',
    value: 'guyana',
    text: (
      <>
        <Flag name='guyana' />
        Guyana
      </>
    ),
  },
  {
    key: 'ht',
    value: 'haiti',
    text: (
      <>
        <Flag name='haiti' />
        Haiti
      </>
    ),
  },
  {
    key: 'hm',
    value: 'heard island',
    text: (
      <>
        <Flag name='heard island' />
        Heard Island
      </>
    ),
  },
  {
    key: 'hn',
    value: 'honduras',
    text: (
      <>
        <Flag name='honduras' />
        Honduras
      </>
    ),
  },
  {
    key: 'hk',
    value: 'hong kong',
    text: (
      <>
        <Flag name='hong kong' />
        Hong Kong
      </>
    ),
  },
  {
    key: 'hu',
    value: 'hungary',
    text: (
      <>
        <Flag name='hungary' />
        Hungary
      </>
    ),
  },
  {
    key: 'is',
    value: 'iceland',
    text: (
      <>
        <Flag name='iceland' />
        Iceland
      </>
    ),
  },
  {
    key: 'in',
    value: 'india',
    text: (
      <>
        <Flag name='india' />
        India
      </>
    ),
  },
  {
    key: 'io',
    value: 'indian ocean territory',
    text: (
      <>
        <Flag name='indian ocean territory' />
        Indian Ocean Territory
      </>
    ),
  },
  {
    key: 'id',
    value: 'indonesia',
    text: (
      <>
        <Flag name='indonesia' />
        Indonesia
      </>
    ),
  },
  {
    key: 'ir',
    value: 'iran',
    text: (
      <>
        <Flag name='iran' />
        Iran
      </>
    ),
  },
  {
    key: 'iq',
    value: 'iraq',
    text: (
      <>
        <Flag name='iraq' />
        Iraq
      </>
    ),
  },
  {
    key: 'ie',
    value: 'ireland',
    text: (
      <>
        <Flag name='ireland' />
        Ireland
      </>
    ),
  },
  {
    key: 'il',
    value: 'israel',
    text: (
      <>
        <Flag name='israel' />
        Israel
      </>
    ),
  },
  {
    key: 'it',
    value: 'italy',
    text: (
      <>
        <Flag name='italy' />
        Italy
      </>
    ),
  },
  {
    key: 'jm',
    value: 'jamaica',
    text: (
      <>
        <Flag name='jamaica' />
        Jamaica
      </>
    ),
  },
  {
    key: 'jp',
    value: 'japan',
    text: (
      <>
        <Flag name='japan' />
        Japan
      </>
    ),
  },
  {
    key: 'jo',
    value: 'jordan',
    text: (
      <>
        <Flag name='jordan' />
        Jordan
      </>
    ),
  },
  {
    key: 'kz',
    value: 'kazakhstan',
    text: (
      <>
        <Flag name='kazakhstan' />
        Kazakhstan
      </>
    ),
  },
  {
    key: 'ke',
    value: 'kenya',
    text: (
      <>
        <Flag name='kenya' />
        Kenya
      </>
    ),
  },
  {
    key: 'ki',
    value: 'kiribati',
    text: (
      <>
        <Flag name='kiribati' />
        Kiribati
      </>
    ),
  },
  {
    key: 'kw',
    value: 'kuwait',
    text: (
      <>
        <Flag name='kuwait' />
        Kuwait
      </>
    ),
  },
  {
    key: 'kg',
    value: 'kyrgyzstan',
    text: (
      <>
        <Flag name='kyrgyzstan' />
        Kyrgyzstan
      </>
    ),
  },
  {
    key: 'la',
    value: 'laos',
    text: (
      <>
        <Flag name='laos' />
        Laos
      </>
    ),
  },
  {
    key: 'lv',
    value: 'latvia',
    text: (
      <>
        <Flag name='latvia' />
        Latvia
      </>
    ),
  },
  {
    key: 'lb',
    value: 'lebanon',
    text: (
      <>
        <Flag name='lebanon' />
        Lebanon
      </>
    ),
  },
  {
    key: 'ls',
    value: 'lesotho',
    text: (
      <>
        <Flag name='lesotho' />
        Lesotho
      </>
    ),
  },
  {
    key: 'lr',
    value: 'liberia',
    text: (
      <>
        <Flag name='liberia' />
        Liberia
      </>
    ),
  },
  {
    key: 'ly',
    value: 'libya',
    text: (
      <>
        <Flag name='libya' />
        Libya
      </>
    ),
  },
  {
    key: 'li',
    value: 'liechtenstein',
    text: (
      <>
        <Flag name='liechtenstein' />
        Liechtenstein
      </>
    ),
  },
  {
    key: 'lt',
    value: 'lithuania',
    text: (
      <>
        <Flag name='lithuania' />
        Lithuania
      </>
    ),
  },
  {
    key: 'lu',
    value: 'luxembourg',
    text: (
      <>
        <Flag name='luxembourg' />
        Luxembourg
      </>
    ),
  },
  {
    key: 'mo',
    value: 'macau',
    text: (
      <>
        <Flag name='macau' />
        Macau
      </>
    ),
  },
  {
    key: 'mk',
    value: 'macedonia',
    text: (
      <>
        <Flag name='macedonia' />
        Macedonia
      </>
    ),
  },
  {
    key: 'mg',
    value: 'madagascar',
    text: (
      <>
        <Flag name='madagascar' />
        Madagascar
      </>
    ),
  },
  {
    key: 'mw',
    value: 'malawi',
    text: (
      <>
        <Flag name='malawi' />
        Malawi
      </>
    ),
  },
  {
    key: 'my',
    value: 'malaysia',
    text: (
      <>
        <Flag name='malaysia' />
        Malaysia
      </>
    ),
  },
  {
    key: 'mv',
    value: 'maldives',
    text: (
      <>
        <Flag name='maldives' />
        Maldives
      </>
    ),
  },
  {
    key: 'ml',
    value: 'mali',
    text: (
      <>
        <Flag name='mali' />
        Mali
      </>
    ),
  },
  {
    key: 'mt',
    value: 'malta',
    text: (
      <>
        <Flag name='malta' />
        Malta
      </>
    ),
  },
  {
    key: 'mh',
    value: 'marshall islands',
    text: (
      <>
        <Flag name='marshall islands' />
        Marshall Islands
      </>
    ),
  },
  {
    key: 'mq',
    value: 'martinique',
    text: (
      <>
        <Flag name='martinique' />
        Martinique
      </>
    ),
  },
  {
    key: 'mr',
    value: 'mauritania',
    text: (
      <>
        <Flag name='mauritania' />
        Mauritania
      </>
    ),
  },
  {
    key: 'mu',
    value: 'mauritius',
    text: (
      <>
        <Flag name='mauritius' />
        Mauritius
      </>
    ),
  },
  {
    key: 'yt',
    value: 'mayotte',
    text: (
      <>
        <Flag name='mayotte' />
        Mayotte
      </>
    ),
  },
  {
    key: 'mx',
    value: 'mexico',
    text: (
      <>
        <Flag name='mexico' />
        Mexico
      </>
    ),
  },
  {
    key: 'fm',
    value: 'micronesia',
    text: (
      <>
        <Flag name='micronesia' />
        Micronesia
      </>
    ),
  },
  {
    key: 'md',
    value: 'moldova',
    text: (
      <>
        <Flag name='moldova' />
        Moldova
      </>
    ),
  },
  {
    key: 'mc',
    value: 'monaco',
    text: (
      <>
        <Flag name='monaco' />
        Monaco
      </>
    ),
  },
  {
    key: 'mn',
    value: 'mongolia',
    text: (
      <>
        <Flag name='mongolia' />
        Mongolia
      </>
    ),
  },
  {
    key: 'me',
    value: 'montenegro',
    text: (
      <>
        <Flag name='montenegro' />
        Montenegro
      </>
    ),
  },
  {
    key: 'ms',
    value: 'montserrat',
    text: (
      <>
        <Flag name='montserrat' />
        Montserrat
      </>
    ),
  },
  {
    key: 'ma',
    value: 'morocco',
    text: (
      <>
        <Flag name='morocco' />
        Morocco
      </>
    ),
  },
  {
    key: 'mz',
    value: 'mozambique',
    text: (
      <>
        <Flag name='mozambique' />
        Mozambique
      </>
    ),
  },
  {
    key: 'na',
    value: 'namibia',
    text: (
      <>
        <Flag name='namibia' />
        Namibia
      </>
    ),
  },
  {
    key: 'nr',
    value: 'nauru',
    text: (
      <>
        <Flag name='nauru' />
        Nauru
      </>
    ),
  },
  {
    key: 'np',
    value: 'nepal',
    text: (
      <>
        <Flag name='nepal' />
        Nepal
      </>
    ),
  },
  {
    key: 'an',
    value: 'netherlands antilles',
    text: (
      <>
        <Flag name='netherlands antilles' />
        Netherlands Antilles
      </>
    ),
  },
  {
    key: 'nl',
    value: 'netherlands',
    text: (
      <>
        <Flag name='netherlands' />
        Netherlands
      </>
    ),
  },
  {
    key: 'nc',
    value: 'new caledonia',
    text: (
      <>
        <Flag name='new caledonia' />
        New Caledonia
      </>
    ),
  },
  {
    key: 'nz',
    value: 'new zealand',
    text: (
      <>
        <Flag name='new zealand' />
        New Zealand
      </>
    ),
  },
  {
    key: 'ni',
    value: 'nicaragua',
    text: (
      <>
        <Flag name='nicaragua' />
        Nicaragua
      </>
    ),
  },
  {
    key: 'ne',
    value: 'niger',
    text: (
      <>
        <Flag name='niger' />
        Niger
      </>
    ),
  },
  {
    key: 'ng',
    value: 'nigeria',
    text: (
      <>
        <Flag name='nigeria' />
        Nigeria
      </>
    ),
  },
  {
    key: 'nu',
    value: 'niue',
    text: (
      <>
        <Flag name='niue' />
        Niue
      </>
    ),
  },
  {
    key: 'nf',
    value: 'norfolk island',
    text: (
      <>
        <Flag name='norfolk island' />
        Norfolk Island
      </>
    ),
  },
  {
    key: 'kp',
    value: 'north korea',
    text: (
      <>
        <Flag name='north korea' />
        North Korea
      </>
    ),
  },
  {
    key: 'mp',
    value: 'northern mariana islands',
    text: (
      <>
        <Flag name='northern mariana islands' />
        Northern Mariana Islands
      </>
    ),
  },
  {
    key: 'no',
    value: 'norway',
    text: (
      <>
        <Flag name='norway' />
        Norway
      </>
    ),
  },
  {
    key: 'om',
    value: 'oman',
    text: (
      <>
        <Flag name='oman' />
        Oman
      </>
    ),
  },
  {
    key: 'pk',
    value: 'pakistan',
    text: (
      <>
        <Flag name='pakistan' />
        Pakistan
      </>
    ),
  },
  {
    key: 'pw',
    value: 'palau',
    text: (
      <>
        <Flag name='palau' />
        Palau
      </>
    ),
  },
  {
    key: 'ps',
    value: 'palestine',
    text: (
      <>
        <Flag name='palestine' />
        Palestine
      </>
    ),
  },
  {
    key: 'pa',
    value: 'panama',
    text: (
      <>
        <Flag name='panama' />
        Panama
      </>
    ),
  },
  {
    key: 'pg',
    value: 'papua new guinea',
    text: (
      <>
        <Flag name='papua new guinea' />
        Papua New Guinea
      </>
    ),
  },
  {
    key: 'py',
    value: 'paraguay',
    text: (
      <>
        <Flag name='paraguay' />
        Paraguay
      </>
    ),
  },
  {
    key: 'pe',
    value: 'peru',
    text: (
      <>
        <Flag name='peru' />
        Peru
      </>
    ),
  },
  {
    key: 'ph',
    value: 'philippines',
    text: (
      <>
        <Flag name='philippines' />
        Philippines
      </>
    ),
  },
  {
    key: 'pn',
    value: 'pitcairn islands',
    text: (
      <>
        <Flag name='pitcairn islands' />
        Pitcairn Islands
      </>
    ),
  },
  {
    key: 'pl',
    value: 'poland',
    text: (
      <>
        <Flag name='poland' />
        Poland
      </>
    ),
  },
  {
    key: 'pr',
    value: 'puerto rico',
    text: (
      <>
        <Flag name='puerto rico' />
        Puerto Rico
      </>
    ),
  },
  {
    key: 'qa',
    value: 'qatar',
    text: (
      <>
        <Flag name='qatar' />
        Qatar
      </>
    ),
  },
  {
    key: 're',
    value: 'reunion',
    text: (
      <>
        <Flag name='reunion' />
        Reunion
      </>
    ),
  },
  {
    key: 'ro',
    value: 'romania',
    text: (
      <>
        <Flag name='romania' />
        Romania
      </>
    ),
  },
  {
    key: 'ru',
    value: 'russia',
    text: (
      <>
        <Flag name='russia' />
        Russia
      </>
    ),
  },
  {
    key: 'rw',
    value: 'rwanda',
    text: (
      <>
        <Flag name='rwanda' />
        Rwanda
      </>
    ),
  },
  {
    key: 'sh',
    value: 'saint helena',
    text: (
      <>
        <Flag name='saint helena' />
        Saint Helena
      </>
    ),
  },
  {
    key: 'kn',
    value: 'saint kitts and nevis',
    text: (
      <>
        <Flag name='saint kitts and nevis' />
        Saint Kitts and Nevis
      </>
    ),
  },
  {
    key: 'lc',
    value: 'saint lucia',
    text: (
      <>
        <Flag name='saint lucia' />
        Saint Lucia
      </>
    ),
  },
  {
    key: 'pm',
    value: 'saint pierre',
    text: (
      <>
        <Flag name='saint pierre' />
        Saint Pierre
      </>
    ),
  },
  {
    key: 'vc',
    value: 'saint vincent',
    text: (
      <>
        <Flag name='saint vincent' />
        Saint Vincent
      </>
    ),
  },
  {
    key: 'ws',
    value: 'samoa',
    text: (
      <>
        <Flag name='samoa' />
        Samoa
      </>
    ),
  },
  {
    key: 'sm',
    value: 'san marino',
    text: (
      <>
        <Flag name='san marino' />
        San Marino
      </>
    ),
  },
  {
    key: 'st',
    value: 'sao tome and principe',
    text: (
      <>
        <Flag name='sao tome and principe' />
        Sao Tome and Principe
      </>
    ),
  },
  {
    key: 'sa',
    value: 'saudi arabia',
    text: (
      <>
        <Flag name='saudi arabia' />
        Saudi Arabia
      </>
    ),
  },
  {
    key: 'sn',
    value: 'senegal',
    text: (
      <>
        <Flag name='senegal' />
        Senegal
      </>
    ),
  },
  {
    key: 'rs',
    value: 'serbia',
    text: (
      <>
        <Flag name='serbia' />
        Serbia
      </>
    ),
  },
  {
    key: 'sc',
    value: 'seychelles',
    text: (
      <>
        <Flag name='seychelles' />
        Seychelles
      </>
    ),
  },
  {
    key: 'sl',
    value: 'sierra leone',
    text: (
      <>
        <Flag name='sierra leone' />
        Sierra Leone
      </>
    ),
  },
  {
    key: 'sg',
    value: 'singapore',
    text: (
      <>
        <Flag name='singapore' />
        Singapore
      </>
    ),
  },
  {
    key: 'sk',
    value: 'slovakia',
    text: (
      <>
        <Flag name='slovakia' />
        Slovakia
      </>
    ),
  },
  {
    key: 'si',
    value: 'slovenia',
    text: (
      <>
        <Flag name='slovenia' />
        Slovenia
      </>
    ),
  },
  {
    key: 'sb',
    value: 'solomon islands',
    text: (
      <>
        <Flag name='solomon islands' />
        Solomon Islands
      </>
    ),
  },
  {
    key: 'so',
    value: 'somalia',
    text: (
      <>
        <Flag name='somalia' />
        Somalia
      </>
    ),
  },
  {
    key: 'za',
    value: 'south africa',
    text: (
      <>
        <Flag name='south africa' />
        South Africa
      </>
    ),
  },
  {
    key: 'kr',
    value: 'south korea',
    text: (
      <>
        <Flag name='south korea' />
        South Korea
      </>
    ),
  },
  {
    key: 'es',
    value: 'spain',
    text: (
      <>
        <Flag name='spain' />
        Spain
      </>
    ),
  },
  {
    key: 'lk',
    value: 'sri lanka',
    text: (
      <>
        <Flag name='sri lanka' />
        Sri Lanka
      </>
    ),
  },
  {
    key: 'sd',
    value: 'sudan',
    text: (
      <>
        <Flag name='sudan' />
        Sudan
      </>
    ),
  },
  {
    key: 'sr',
    value: 'suriname',
    text: (
      <>
        <Flag name='suriname' />
        Suriname
      </>
    ),
  },
  {
    key: 'sj',
    value: 'svalbard and jan mayen',
    text: (
      <>
        <Flag name='svalbard and jan mayen' />
        Svalbard and Jan Mayen
      </>
    ),
  },
  {
    key: 'sz',
    value: 'swaziland',
    text: (
      <>
        <Flag name='swaziland' />
        Swaziland
      </>
    ),
  },
  {
    key: 'se',
    value: 'sweden',
    text: (
      <>
        <Flag name='sweden' />
        Sweden
      </>
    ),
  },
  {
    key: 'ch',
    value: 'switzerland',
    text: (
      <>
        <Flag name='switzerland' />
        Switzerland
      </>
    ),
  },
  {
    key: 'sy',
    value: 'syria',
    text: (
      <>
        <Flag name='syria' />
        Syria
      </>
    ),
  },
  {
    key: 'tw',
    value: 'taiwan',
    text: (
      <>
        <Flag name='taiwan' />
        Taiwan
      </>
    ),
  },
  {
    key: 'tj',
    value: 'tajikistan',
    text: (
      <>
        <Flag name='tajikistan' />
        Tajikistan
      </>
    ),
  },
  {
    key: 'tz',
    value: 'tanzania',
    text: (
      <>
        <Flag name='tanzania' />
        Tanzania
      </>
    ),
  },
  {
    key: 'th',
    value: 'thailand',
    text: (
      <>
        <Flag name='thailand' />
        Thailand
      </>
    ),
  },
  {
    key: 'tl',
    value: 'timor-leste',
    text: (
      <>
        <Flag name='timor-leste' />
        Timor-Leste
      </>
    ),
  },
  {
    key: 'tg',
    value: 'togo',
    text: (
      <>
        <Flag name='togo' />
        Togo
      </>
    ),
  },
  {
    key: 'tk',
    value: 'tokelau',
    text: (
      <>
        <Flag name='tokelau' />
        Tokelau
      </>
    ),
  },
  {
    key: 'to',
    value: 'tonga',
    text: (
      <>
        <Flag name='tonga' />
        Tonga
      </>
    ),
  },
  {
    key: 'tt',
    value: 'trinidad and tobago',
    text: (
      <>
        <Flag name='trinidad and tobago' />
        Trinidad and Tobago
      </>
    ),
  },
  {
    key: 'tn',
    value: 'tunisia',
    text: (
      <>
        <Flag name='tunisia' />
        Tunisia
      </>
    ),
  },
  {
    key: 'tr',
    value: 'turkey',
    text: (
      <>
        <Flag name='turkey' />
        Turkey
      </>
    ),
  },
  {
    key: 'tm',
    value: 'turkmenistan',
    text: (
      <>
        <Flag name='turkmenistan' />
        Turkmenistan
      </>
    ),
  },
  {
    key: 'tv',
    value: 'tuvalu',
    text: (
      <>
        <Flag name='tuvalu' />
        Tuvalu
      </>
    ),
  },
  {
    key: 'ug',
    value: 'Uganda',
    text: (
      <>
        <Flag name='uganda' />
        Uganda
      </>
    ),
  },
  {
    key: 'ua',
    value: 'Ukraine',
    text: (
      <>
        <Flag name='ukraine' />
        Ukraine
      </>
    ),
  },
  {
    key: 'ae',
    value: 'United Arab Emirates',
    text: (
      <>
        <Flag name='united arab emirates' />
        United Arab Emirates
      </>
    ),
  },
  {
    key: 'gb',
    value: 'United Kingdom',
    text: (
      <>
        <Flag name='united kingdom' />
        United Kingdom
      </>
    ),
  },
  {
    key: 'us',
    value: 'United States',
    text: (
      <>
        <Flag name='united states' />
        United States
      </>
    ),
  },
  {
    key: 'um',
    value: 'United States Minor Outlying Islands',
    text: (
      <>
        <Flag name='united states minor outlying islands' />
        United States Minor Outlying Islands
      </>
    ),
  },
  {
    key: 'uy',
    value: 'Uruguay',
    text: (
      <>
        <Flag name='uruguay' />
        Uruguay
      </>
    ),
  },
  {
    key: 'uz',
    value: 'Uzbekistan',
    text: (
      <>
        <Flag name='uzbekistan' />
        Uzbekistan
      </>
    ),
  },
  {
    key: 'vu',
    value: 'Vanuatu',
    text: (
      <>
        <Flag name='vanuatu' />
        Vanuatu
      </>
    ),
  },
  {
    key: 'va',
    value: 'Vatican City',
    text: (
      <>
        <Flag name='vatican city' />
        Vatican City
      </>
    ),
  },
  {
    key: 've',
    value: 'Venezuela',
    text: (
      <>
        <Flag name='venezuela' />
        Venezuela
      </>
    ),
  },
  {
    key: 'vn',
    value: 'Vietnam',
    text: (
      <>
        <Flag name='vietnam' />
        Vietnam
      </>
    ),
  },
  {
    key: 'vg',
    value: 'British Virgin Islands',
    text: (
      <>
        <Flag name='british virgin islands' />
        British Virgin Islands
      </>
    ),
  },
  {
    key: 'vi',
    value: 'US Virgin Islands',
    text: (
      <>
        <Flag name='us virgin islands' />
        US Virgin Islands
      </>
    ),
  },
  {
    key: 'wf',
    value: 'wallis and futuna',
    text: (
      <>
        <Flag name='wallis and futuna' />
        Wallis and Futuna
      </>
    ),
  },
  {
    key: 'eh',
    value: 'western sahara',
    text: (
      <>
        <Flag name='western sahara' />
        Western Sahara
      </>
    ),
  },
  {
    key: 'ye',
    value: 'yemen',
    text: (
      <>
        <Flag name='yemen' />
        Yemen
      </>
    ),
  },
  {
    key: 'zm',
    value: 'zambia',
    text: (
      <>
        <Flag name='zambia' />
        Zambia
      </>
    ),
  },
  {
    key: 'zw',
    value: 'zimbabwe',
    text: (
      <>
        <Flag name='zimbabwe' />
        Zimbabwe
      </>
    ),
  },
];

export default countryOptions;
