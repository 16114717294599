import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

const storeTokenInLocalStorage = (token) => {
  localStorage.setItem('jwtToken', token);
};

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (userData, { dispatch, rejectWithValue }) => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };

    const formData = new FormData();
    for (const key in userData) {
      formData.append(key, userData[key]);
    }

    try {
      const response = await api.post('/login', formData, config);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  userInfo: {},
  userToken: Cookies.get('token') || null,
  error: null,
  success: false,
  isLogged: Cookies.get('token') ? true : false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.userInfo = action.payload.userInfo;
      state.userToken = action.payload.userToken;
      state.error = null;
      state.success = true;
      state.isLogged = true;
      storeTokenInLocalStorage(action.payload.userToken);
      Cookies.set('userToken', action.payload.userToken, { secure: true });
    },
    loginFailure: (state, action) => {
      state.error = action.payload.error;
      state.success = false;
    },
    logout: (state) => {
      state.userInfo = {};
      state.userToken = null;
      state.error = null;
      state.success = false;
      state.isLogged = false;
      localStorage.removeItem('jwtToken');
      Cookies.remove('token');
    },
    updateData: (state, action) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userInfo = action.payload.userInfo;
        state.userToken = action.payload.userToken;
        state.error = null;
        state.success = true;
        state.isLogged = true;
        storeTokenInLocalStorage(action.payload.userToken);
        Cookies.set('userToken', action.payload.userToken, { secure: true });
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload.error || 'Erro desconhecido ao efetuar o login';
        state.success = false;
      });
  },
});

export const { loginSuccess, loginFailure, logout, updateData } = authSlice.actions;

export default authSlice.reducer;
