import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../features/auth/authSlice';
import api from '../api';

const LeagueRegistration = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userInfo.id);

  const [leagueId, setLeagueId] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [leagues, setLeagues] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [formData, setFormData] = useState({
    nationality: '',
  });

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    fetchLeagues();
  }, []);

  const fetchLeagues = async () => {
    try {
      const response = await api.get('/league/listRegister');
      setLeagues(response.data);
    } catch (error) {
      console.error('Error fetching leagues:', error);
    }
  };

  const handleRegister = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const response = await api.post(
        '/league/register',
        {
          userId,
          leagueId,
        },
        config
      );

      setMessage(response.data.message);
      setError('');
    } catch (error) {
      setMessage('');
      setError(error.response.data.error);
    }
  };

  const handleOptionSelect = (value, name) => {
    setLeagueId(value);
    setFormData({
      ...formData,
      nationality: name,
    });
    setShowDropdown(false);
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-transparent'>
      <div
        className={`flex flex-col border-violet-400 border-2 w-96 bg-neutral-900 p-5 rounded-xl justify-center`}
      >
        <h2 className='text-2xl text-white font-bold mb-4'>Registrar-se em uma Liga</h2>
        <div>
          <label htmlFor='nationality' className='block font-semibold text-white'>
            Registre-se numa liga:
          </label>
          <div
            className={`relative mt-1 bg-white rounded-t-md w-full ${
              showDropdown ? '' : 'rounded-b-md'
            }`}
          >
            <div
              onClick={toggleDropdown}
              className='cursor-pointer appearance-none p-2 bg-transparent w-full flex justify-between items-center'
            >
              <span>{formData.nationality || 'Selecione a liga'}</span>
              <span>{showDropdown ? '▲' : '▼'}</span>
            </div>
            {showDropdown && (
              <div className='absolute top-full left-0 z-10 bg-white shadow-lg border border-gray-300 overflow-y-auto w-full max-h-64 rounded-b-md'>
                {leagues.map((league) => (
                  <div
                    key={league.id}
                    onClick={() => handleOptionSelect(league.id, league.Name)}
                    className='p-2 hover:bg-gray-200 cursor-pointer'
                  >
                    {league.Name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {message && <div className='text-green-600'>{message}</div>}
        {error && <div className='text-red-600'>{error}</div>}
        <button
          onClick={handleRegister}
          className='bg-violet-800 text-white py-2 px-4 mt-5 rounded-md hover:bg-violet-700 focus:outline-none active:bg-violet-500 focus:ring-none'
        >
          Registrar-se
        </button>
      </div>
    </div>
  );
};

export default LeagueRegistration;
