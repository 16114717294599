import { Flag } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';

function PlayerCard({ user }) {
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const location = useLocation();

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/':
        return 'border-yellow-500';
      default:
        return '';
    }
  };

  return (
    <Link to={`/Perfil/${user.id}`} className='w-full h-full'>
      <div
        className={`flex flex-row gap-3 bg-neutral-800 border-2 ${getCurrentBorderColor()} rounded-xl shadow-lg p-3 justify-start items-center text-center`}
      >
        <div className='w-28 h-28 flex-col rounded-full flex justify-center items-center bg-neutral-900'>
          <img
            className='w-24 h-24 rounded-full object-cover'
            src={`https://api.ligas.purplews.live/photos/` + user.Photo}
            alt={user.id}
          />
        </div>
        <div className='flex-col'>
          <div className='font-bold text-lg text-white text-start'>
            {user.name} <Flag name={user.Nacionality} />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default PlayerCard;
