import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from '../appPages/Home';
import Header from '../components/Header';
import League from '../appPages/League';
import Footer from '../components/Footer';
import PlayerList from '../appPages/PlayerList';
import Perfil from '../appPages/Perfil';
import Login from '../appPages/Login';
import Register from '../appPages/Register';
import { Provider } from 'react-redux';
import { store } from './store';
import LeagueRegistration from '../components/LeagueRegistration';
import Settings from '../components/Settings';
import Enable from '../components/Enable';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className='App min-h-screen bg-transparent pb-24 relative'>
          <img
            src='/image/homeBG.jpg'
            alt='Background'
            className='fixed inset-0 w-full h-full object-cover z-[-1]'
          />
          <Header />
          <ToastContainer position='top-right' />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/Ligas' element={<League />} />
            <Route exact path='/Usuarios' element={<PlayerList />} />
            <Route exact path='/Perfil/:userid' element={<Perfil />} />
            <Route exact path='/Inscrição' element={<LeagueRegistration />} />
            <Route exact path='/Login' element={<Login />} />
            <Route exact path='/Register' element={<Register />} />
            <Route exact path='/Settings/:userid' element={<Settings />} />
            <Route exact path='/Register/accept' element={<Enable />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
