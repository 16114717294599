import React, { useState, useEffect } from 'react';
import { Flag } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import api from '../api';
import moment from 'moment';

export default function MatchCard() {
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const location = useLocation();
  const [nextGame, setNextGame] = useState(null);
  const [isNextGameAvailable, setIsNextGameAvailable] = useState(false);
  const [isSameDay, setIsSameDay] = useState(false);
  const [countdown, setCountdown] = useState('');
  const [leagueUsers, setLeagueUsers] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [selectedLeagueId, setSelectedLeagueId] = useState(null);
  const [users, setUsers] = useState([]);

  const fetchLeagues = async () => {
    try {
      const response = await api.get('/league/list');
      setLeagues(response.data);
    } catch (error) {
      console.error('Error fetching leagues:', error);
    }
  };

  useEffect(() => {
    fetchLeagues();
  }, []);

  const fetchUsers = async (leagueId) => {
    try {
      const response = await api.get(`/league/details/${leagueId}`);
      const sortedUsers = response.data.users.sort((a, b) => b.W - a.W);
      setUsers(sortedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    if (nextGame) {
      fetchUsers(nextGame.League_id);
    }
  }, [nextGame]);

  useEffect(() => {
    api
      .get('/games/upcoming')
      .then((response) => {
        if (response.data.length > 0) {
          const upcomingGame = response.data[0];
          setNextGame(upcomingGame);
          setIsNextGameAvailable(true);

          const gameDate = moment(upcomingGame.Date);
          const now = moment();
          const diff = gameDate.diff(now);

          if (gameDate.isSame(now, 'day')) {
            setIsSameDay(true);
            updateCountdown(diff);
          } else {
            setIsSameDay(false);
            const daysRemaining = gameDate.diff(now, 'days');
            setCountdown(`${daysRemaining} dias`);
          }

          const interval = setInterval(() => {
            const gameDate = moment(upcomingGame.Date);
            const now = moment();
            const diff = gameDate.diff(now);

            if (gameDate.isSame(now, 'day')) {
              setIsSameDay(true);
              updateCountdown(diff);
            } else {
              setIsSameDay(false);
              const daysRemaining = gameDate.diff(now, 'days');
              const pluralSuffix = daysRemaining === 1 ? 'dia' : 'dias';
              setCountdown(`${daysRemaining} ${pluralSuffix}`);
            }
          }, 1000);

          return () => clearInterval(interval);
        }
      })
      .catch((error) => console.error('Error fetching next game:', error));
  }, []);

  useEffect(() => {
    if (nextGame) {
      fetchLeagueUsers(nextGame.League_id);
      setSelectedLeagueId(nextGame.League_id);
    }
  }, [nextGame]);

  const fetchLeagueUsers = async (leagueId) => {
    try {
      const response = await api.get(`/league/details/${leagueId}`);
      const filteredUsers = response.data.users.filter(
        (user) => user.type === 'captain' || user.type === 'player'
      );
      const sortedUsers = filteredUsers.sort((a, b) => b.W - a.W);
      setLeagueUsers(sortedUsers);
    } catch (error) {
      console.error('Error fetching league users:', error);
    }
  };

  const updateCountdown = (diff) => {
    const duration = moment.duration(diff);
    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');
    setCountdown(`${hours}:${minutes}:${seconds}`);
  };

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/':
        return 'border-yellow-500';
      default:
        return '';
    }
  };

  if (!isNextGameAvailable) {
    return (
      <div className='h-full w-full text-lg text-white font-bold flex items-center justify-center'>
        Sem jogo disponível.
      </div>
    );
  }

  const player1 = nextGame.Hands[0].User1;
  const player2 = nextGame.Hands[0].User2;

  return (
    <>
      {/** IF START HERE */}
      <div className='h-full w-full'>
        <div className='p-3 grid grid-cols-3 items-start text-center bg-neutral-800 rounded-t-xl'>
          <div
            className={`flex flex-col ${player1.colors} rounded-lg p-5 justify-center items-center content-center overflow-hidden`}
          >
            {/* Conteúdo do jogador 1 */}
            <div className='w-28 h-28 rounded-full flex justify-center items-center bg-neutral-900'>
              <img
                className='w-24 h-24 rounded-full object-cover'
                src={`https://api.ligas.purplews.live/photos/` + player1.Photo}
                alt={player1.id}
              />
            </div>
            <div className='bg-neutral-800 flex object-contain flex-grow flex-shrink text-start items-center justify-start max-h-16 min-h-14 min-w-8 max-w-40 py-2 px-3 text-white my-2 rounded-xl font-bold xl:text-lg lg:text-lg md:text-md text-xs overflow-hidden whitespace-nowrap text-ellipsis'>
              {isExtraSmallScreen ? (
                <div className='flex flex-col text-wrap item-center'>
                  <div className='flex wrap text-center item-center'>{player1.name}</div>
                </div>
              ) : (
                <div className='select-none text-sm flex justify-between items-center text-start overflow-hidden text-ellipsis whitespace-nowrap w-auto max-w-40'>
                  <div className='flex flex-col'>
                    <Flag name={player1.Nacionality} />
                  </div>
                  <div className='flex flex-col text-wrap item-center'>
                    <div className='flex wrap text-center item-center'>{player1.name}</div>
                  </div>
                </div>
              )}
            </div>
            <div className='select-none bg-neutral-800 flex text-start items-center justify-center w-20 max-h-6 flex-grow flex-shrink py-1 text-white my-2 rounded-xl md:text-sm text-xs'>
              #7º
            </div>
          </div>

          <div className='flex flex-col gap-3 justify-center items-center bg-neutral-800 h-full flex-grow flex-shrink overflow-hidden'>
            {/* Informações do jogo */}
            <div className='bg-neutral-800 rounded-xl'>
              <div className='font-bold text-sm sm:text-md sm:px-4 px-2 pt-2 my-0.5 text-white'>
                Liga {nextGame.League_name}
              </div>
              <div className='font-bold text-sm sm:text-md pt-2 sm:pb-4 pb-2 sm:px-4 px-2 my-0.5 text-white'>
                {moment(nextGame.Date).format('H:mm')}
              </div>
              <div className='text-xs sm:px-4 px-2 pb-2 text-white'>{nextGame.Location}</div>
            </div>
            <div className='font-bold bg-neutral-800 rounded-xl sm:w-24 w-24 text-sm py-2 sm:px-4 px-2 mt-5 mb-1 text-neutral-300'>
              {isSameDay ? countdown : countdown}
            </div>
          </div>

          <div
            className={`flex flex-col ${player2.colors} rounded-lg p-5 justify-center items-center content-center h-full overflow-hidden`}
          >
            {/* Conteúdo do jogador 2 */}
            <div className='w-28 h-28 rounded-full flex justify-center items-center bg-neutral-900'>
              <img
                className='w-24 h-24 rounded-full object-cover mx-auto'
                src={`https://api.ligas.purplews.live/photos/` + player2.Photo}
                alt={player2.id}
              />
            </div>
            <div className='bg-neutral-800 flex object-contain flex-grow flex-shrink text-start items-center justify-start max-h-16 min-h-14 min-w-8 max-w-40 py-2 px-3 text-white my-2 rounded-xl font-bold xl:text-lg lg:text-lg md:text-md text-xs overflow-hidden whitespace-nowrap text-ellipsis'>
              {isExtraSmallScreen ? (
                <div className='flex flex-col text-wrap item-center'>
                  <div className='flex wrap text-center item-center'>{player2.name}</div>
                </div>
              ) : (
                <div className='select-none text-sm flex justify-between items-center text-start overflow-hidden text-ellipsis whitespace-nowrap w-auto max-w-40'>
                  <div className='flex flex-col'>
                    <Flag name={player2.Nacionality} />
                  </div>
                  <div className='flex flex-col text-wrap item-center'>
                    <div className='flex wrap text-center item-center'>{player2.name}</div>
                  </div>
                </div>
              )}
            </div>
            <div className='select-none bg-neutral-800 flex text-start items-center justify-center w-20 max-h-6 flex-grow flex-shrink py-1 text-white my-2 rounded-xl md:text-sm text-xs'>
              #7º
            </div>
          </div>
        </div>
        <div className='p-4 grid grid-cols-3 text-base text-white items-start text-center'>
          <div className='col-span-1 flex justify-start text-center'>
            <div className='w-64 h-32 flex flex-col items-center justify-start'>
              <div className='flex flex-row'>{player1.Jogos ?? 0}</div>
              <div className='flex flex-row'>{player1.W ?? 0}</div>
              <div className='flex flex-row'>{player1.L ?? 0}</div>
              <div className='flex flex-row'>{player1.win_ratio ?? 0}%</div>
              <div className='flex flex-row'>{player1.Age ?? 0}</div>
            </div>
          </div>
          <div className='col-span-1 flex justify-end text-center'>
            <div className='w-64 h-32 flex flex-col items-center justify-start'>
              <div className='flex flex-row'>Jogos: </div>
              <div className='flex flex-row'>Vitórias: </div>
              <div className='flex flex-row'>Derrotas: </div>
              <div className='flex flex-row'>Win%: </div>
              <div className='flex flex-row'>Idade: </div>
            </div>
          </div>
          <div className='col-span-1 flex justify-end text-center'>
            <div className='w-64 h-32 flex flex-col items-center justify-start'>
              <div className='flex flex-row'>{player2.Jogos ?? 0}</div>
              <div className='flex flex-row'>{player2.W ?? 0}</div>
              <div className='flex flex-row'>{player2.L ?? 0}</div>
              <div className='flex flex-row'>{player2.win_ratio ?? 0}%</div>
              <div className='flex flex-row'>{player2.Age ?? 0}</div>
            </div>
          </div>
        </div>
        <div className='flex flex-col px-10 pt-5 text-white h-72 w-full'>
          <div className='flex flex-row justify-between w-full font-bold'>
            <div className='flex gap-5'>
              <div className='ml-3'>Pos</div>
              <div>Nome</div>
            </div>
            <div className='flex gap-5'>
              <div className='flex justify-end'>Jog</div>
              <div className='flex justify-end'>Pontos</div>
            </div>
          </div>

          <div className='p-4 grid grid-cols-1 text-base text-white items-start'>
            {leagueUsers.length > 0 ? (
              leagueUsers.map((user, index) => (
                <div className='flex flex-row justify-between w-full font-bold' key={index}>
                  <div className='flex gap-5'>
                    <div className=''>#{index + 1}</div>
                    <div>{user.name}</div>
                  </div>
                  <div className='flex gap-5'>
                    <div className='flex justify-end'>{user.games}</div>
                    <div className='flex w-10 justify-end'>{user.pontos}</div>
                  </div>
                </div>
              ))
            ) : (
              <div>No users found in this league.</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
