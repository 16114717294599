import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from '../api';

const LeagueSelection = ({ onSelectLeague }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [leagues, setLeagues] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.userToken);

  useEffect(() => {
    fetchLeagues();
  }, []);

  const fetchLeagues = async () => {
    try {
      const response = await api.get('/league/listOnGoing');
      setLeagues(response.data);
    } catch (error) {
      console.error('Error fetching leagues:', error);
    }
  };

  const handleLeagueClick = (league) => {
    onSelectLeague(league);
    setSelectedLeague(league);
  };

  const handleNavigateToPage = () => {
    navigate('/Inscrição');
  };

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/':
        return 'border-yellow-500';
      default:
        return '';
    }
  };

  // Filtrar as ligas com base no searchTerm
  const filteredLeagues = leagues.filter((league) =>
    league.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='w-full h-full flex flex-col max-w-md bg-neutral-800 content-between justify-between item-between rounded-xl p-2'>
      <div className='flex-row'>
        <input
          type='text'
          placeholder='Search leagues...'
          className={`w-11/12 border-2 m-2 ${getCurrentBorderColor()} bg-neutral-900 text-white rounded-md px-4 py-2 mb-4 focus:outline-none`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className='overflow-y-auto max-h-44'>
          {filteredLeagues.map((league) => (
            <div
              key={league.id}
              className={`flex cursor-pointer select-none text-white font-bold bg-neutral-900 active:bg-black hover:bg-neutral-950 rounded-xl m-2 justify-center text-center items-center py-1.5`}
              onClick={() => handleLeagueClick(league)}
            >
              <span>{league.Name}</span>
            </div>
          ))}
        </div>
      </div>

      <div className='flex-row'>
        {isAuthenticated && (
          <div className='flex justify-center'>
            {' '}
            <button
              onClick={handleNavigateToPage}
              className='bg-lime-500 hover:bg-lime-700 text-white font-bold py-2 px-4 rounded my-3'
            >
              Inscreve-te numa liga
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeagueSelection;
