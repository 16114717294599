import React from 'react';
import { useNavigate } from 'react-router-dom';

const Enable = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className='min-h-screen flex items-center justify-center bg-neutral-900'>
      <div className='bg-neutral-800 text-white p-8 rounded-md shadow-md text-center'>
        <h1 className='text-2xl font-bold mb-4'>Obrigado pelo Registo</h1>
        <p>
          Os administradores irão ver o seu registo e será enviada uma mensagem quando o registo for
          confirmado.
        </p>
        <button
          onClick={handleBackToHome}
          className='mt-6 w-full flex justify-center py-2 px-4 border-2 border-transparent text-sm font-bold rounded-md text-neutral-800 bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-400 hover:border-neutral-300 active:border-neutral-400'
        >
          Voltar para a Página Principal
        </button>
      </div>
    </div>
  );
};

export default Enable;
