const colorOptions = [
  {
    key: 'white',
    value: 'neutral',
    text: (
      <>
        <i className='pt flag' />
        Branco
      </>
    ),
  },
  {
    key: 'red',
    value: 'red',
    text: (
      <>
        <i className='pt flag' />
        Vermelho
      </>
    ),
  },
  {
    key: 'orange',
    value: 'orange',
    text: (
      <>
        <i className='pt flag' />
        Laranja
      </>
    ),
  },
  {
    key: 'yellow',
    value: 'yellow',
    text: (
      <>
        <i className='pt flag' />
        Amarelo
      </>
    ),
  },
  {
    key: 'lime',
    value: 'lime',
    text: (
      <>
        <i className='pt flag' />
        Lima
      </>
    ),
  },
  {
    key: 'green',
    value: 'green',
    text: (
      <>
        <i className='pt flag' />
        Verde
      </>
    ),
  },
  {
    key: 'cyan',
    value: 'cyan',
    text: (
      <>
        <i className='pt flag' />
        Ciano
      </>
    ),
  },
  {
    key: 'blue',
    value: 'blue',
    text: (
      <>
        <i className='pt flag' />
        Azul
      </>
    ),
  },
  {
    key: 'violet',
    value: 'violet',
    text: (
      <>
        <i className='pt flag' />
        Violeta
      </>
    ),
  },
  {
    key: 'pink',
    value: 'fuchsia',
    text: (
      <>
        <i className='pt flag' />
        Rosa
      </>
    ),
  },
];

export default colorOptions;
