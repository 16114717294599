import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import PlayerCard from '../components/PlayerCard';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import api from '../api';

function PlayerList() {
  const location = useLocation();
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/user/listNotAdmin');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isBodyJunction = useMediaQuery({ query: '(max-width: 1005px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/':
        return 'border-yellow-500';
      default:
        return '';
    }
  };

  return (
    <div
      className={`w-full bg-transparent transition-transform duration-500 ease-in-out transform ${isBodyJunction ? 'translate-y-0' : 'translate-y-10 mb-16'
        }`}
    >
      <div className='flex justify-center'>
        <div
          className='bg-neutral-700 w-full shadow-xl pb-20'
          style={{
            maxWidth: '1005px',
            minHeight: '822px',
          }}>
          <div
            className={`w-full ${getCurrentBorderColor()} border-b-8 flex flex-row gap-5 text-white text-2xl font-bold py-3 pl-6 bg-neutral-900 items-center`}
          >
            <div className='flex-col'>
              <Icon
                icon='mage:users-fill'
                width='2.5rem'
                height='2.5rem'
                style={{ color: 'white' }}
              />
            </div>
            <div className='flex-col items-center content-center justify-center'>Jogadores</div>
          </div>

          <div className='w-full flex flex-row gap-5 text-white text-2xl font-bold pb-3 pt-4 pl-6 bg-neutral-800 items-center'>
            <input
              type='text'
              placeholder='Procura Jogadores...'
              className={`w-11/12 text-lg border-2 m-2 ${getCurrentBorderColor()} bg-neutral-900 text-white rounded-md px-4 py-2 mb-4 focus:outline-none`}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>

          {isSmallScreen && !isExtraSmallScreen && (
            <div className='grid grid-cols-2 gap-5 p-5'>
              {filteredUsers.map((user, index) => (
                <div key={index} className='flex h-full w-full'>
                  <PlayerCard user={user} />
                </div>
              ))}
            </div>
          )}

          {!isSmallScreen && !isExtraSmallScreen && (
            <div className='grid grid-cols-3 gap-5 p-5'>
              {filteredUsers.map((user, index) => (
                <div key={index} className='flex h-full w-full'>
                  <PlayerCard user={user} />
                </div>
              ))}
            </div>
          )}

          {isExtraSmallScreen && (
            <div className='grid grid-cols-1 gap-5 p-5'>
              {filteredUsers.map((user, index) => (
                <div key={index} className='flex h-full w-full'>
                  <PlayerCard user={user} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlayerList;
