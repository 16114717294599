import React, { useState } from 'react';
import LeagueTable from '../components/LeagueTable';
import { useMediaQuery } from 'react-responsive';
import LeagueSelection from '../components/LeagueSelection';
import UpcomingMatch from '../components/UpcomingMatch';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import RecentGames from '../components/RecentGames';

function League() {
  const [selectedLeague, setSelectedLeague] = useState(null);

  const handleLeagueSelection = (league) => {
    setSelectedLeague(league);
  };

  const location = useLocation();

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/':
        return 'border-yellow-500';
    }
  };

  const isBodyJunction = useMediaQuery({ query: '(max-width: 1005px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  return (
    <div
      className={`relative w-full bg-transparent transition-transform duration-500 ease-in-out transform ${isBodyJunction ? 'translate-y-0' : 'translate-y-10 mb-16'
        }`}
    >
      <div className='flex justify-center'>
        <div
          className='bg-neutral-600 w-full shadow-xl h-full'
          style={{
            maxWidth: '1005px',
            minHeight: '810px',
          }}>
          <div
            className={`w-full ${getCurrentBorderColor()} border-b-8 flex flex-row gap-5 text-white text-2xl font-bold py-3 pl-6 bg-neutral-900 items-center`}
          >
            <div className='flex-col'>
              <Icon
                icon='mdi:snooker-rack'
                width='2.5rem'
                height='2.5rem'
                style={{ color: 'white' }}
              />
            </div>
            <div className='flex-col items-center content-center justify-center'>Ligas</div>
          </div>

          {isSmallScreen && !isExtraSmallScreen && (
            <div className='grid h-full grid-cols-1 p-5 gap-5 flex-grow items-center justify-center'>
              <div className='grid grid-cols-2 gap-5 flex-grow items-center pt-8 content-between justify-between'>
                <div className={`w-full h-full flex justify-center row-span-2 border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}>
                  <LeagueSelection onSelectLeague={handleLeagueSelection} />
                </div>
                <div className={`w-full h-80 border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}>
                  <RecentGames />
                </div>
                <div className={`w-full h-80 border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}>
                  <UpcomingMatch />
                </div>
              </div>
              <div className='flex flex-grow flex-shrink bg-neutral-800 border-2 border-lime-500 rounded-xl items-start justify-start overflow-x-auto'>
                <LeagueTable selectedLeague={selectedLeague} />
              </div>
            </div>
          )}

          {isExtraSmallScreen && (
            <div className='grid h-full grid-cols-1 flex-grow gap-0 pb-8 items-center justify-center'>
              <div className='flex flex-col gap-8 flex-grow items-between pt-6 content-between justify-between'>
                <div className='flex w-full items-center justify-center content-center'>
                  <LeagueSelection onSelectLeague={handleLeagueSelection} />
                </div>
                <div className='flex flex-grow flex-shrink m-5 bg-neutral-800 border-2 border-lime-500 rounded-xl items-start justify-start overflow-x-auto'>
                  <LeagueTable selectedLeague={selectedLeague} />
                </div>
                <div
                  className={`flex flex-grow h-80 py-3 flex-shrink items-center justify-center border-y-2 ${getCurrentBorderColor()} bg-neutral-800`}
                >
                  <UpcomingMatch />
                </div>
                <div
                  className={`flex flex-grow h-80 py-3 flex-shrink items-center justify-center border-y-2 ${getCurrentBorderColor()} bg-neutral-800`}
                >
                  <RecentGames />
                </div>
              </div>
            </div>
          )}

          {!isSmallScreen && !isExtraSmallScreen && (
            <div className='flex columns-2 p-5 h-full flex-grow gap-5 items-start justify-start'>
              <div className='grid grid-cols-3 gap-5 flex-grow items-between content-between justify-between'>
                <div
                  className={`w-full border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
                  style={{ height: '310px' }}
                >
                  <LeagueSelection onSelectLeague={handleLeagueSelection} />
                </div>
                <div
                  className={`w-full border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
                  style={{ height: '310px' }}
                >
                  <RecentGames />
                </div>
                <div
                  className={`w-full border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
                  style={{ height: '310px' }}
                >
                  <UpcomingMatch />
                </div>
                <div
                  className='flex-shrink col-span-3 bg-neutral-800 border-2 border-lime-500 rounded-xl items-start justify-start overflow-x-auto'
                  style={{
                    maxWidth: '1000px',
                    minHeight: '862px',
                  }}
                >
                  <LeagueTable selectedLeague={selectedLeague} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default League;
