import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/auth/authSlice';

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  // Get authentication state from Redux
  const isAuthenticated = useSelector((state) => state.auth.userToken);
  const userInfo = useSelector((state) => state.auth.userInfo);

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/Login':
        return 'border-indigo-400';
      case '/Register':
        return 'border-violet-400';
      case '/':
        return 'border-yellow-500';
    }
  };

  const routeLeague = () => {
    navigate('/Ligas');
  };

  const routeHome = () => {
    navigate('/');
  };

  const routeUserList = () => {
    navigate('/Usuarios');
  };

  const routeProfile = () => {
    if (userInfo && userInfo.id) {
      navigate(`/Perfil/${userInfo.id}`);
    }
  };

  const routeLogin = () => {
    navigate('/Login');
  };

  const routeRegister = () => {
    navigate('/Register');
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <div className='sticky top-0 left-0 select-none justify-center shadow-xl items-center bg-neutral-600 h-16 w-full z-50'>
      <div className={`border-b-2 ${getCurrentBorderColor()}`}>
        <div className='flex justify-center'>
          <div className='h-full bg-transparent p-1.5' style={{ width: '1005px' }}>
            <div className='flex text-sm flex-row items-between justify-between m-1'>
              <div className='flex flex-row gap-2'>
                {isSmallScreen && !isExtraSmallScreen && (
                  <div onClick={routeHome} className='cursor-pointer items-center justify-center'>
                    <img
                      className={`select-none h-10 w-10 object-cover rounded-2xl cursor-pointer
                        ${isSmallScreen ? 'transition ease-in-out delay-150 scale-100 duration-300' : 'transition ease-in-out delay-150 scale-50 duration-300'}`}
                      style={{ maxWidth: '275px', height: 'auto' }}
                      src='image/PoolLogoBall.svg'
                      alt='POOL'
                    />
                  </div>
                )}
                <div
                  onClick={routeHome}
                  className={`w-auto px-2 bg-neutral-900 border-2 border-transparent hover:${getCurrentBorderColor()} active:bg-neutral-800 pt-1.5 h-10 text-white text-md cursor-pointer rounded-lg text-center justify-center`}
                >
                  Home
                </div>
                <div
                  onClick={routeLeague}
                  className={`w-auto px-2 bg-neutral-900 border-2 border-transparent hover:${getCurrentBorderColor()} active:bg-neutral-800 pt-1.5 h-10 text-white text-md cursor-pointer rounded-lg text-center justify-center`}
                >
                  Ligas
                </div>
                <div
                  onClick={routeUserList}
                  className={`w-auto px-2 bg-neutral-900 border-2 border-transparent hover:${getCurrentBorderColor()} active:bg-neutral-800 pt-1.5 h-10 text-white text-md cursor-pointer rounded-lg text-center justify-center`}
                >
                  Jogadores
                </div>
              </div>
              <div className='flex flex-row gap-2'>
                {isAuthenticated && (
                  <div
                    onClick={routeProfile}
                    className={`w-auto px-2 bg-neutral-900 border-2 border-transparent hover:${getCurrentBorderColor()} active:bg-neutral-800 pt-1.5 h-10 text-white text-md cursor-pointer rounded-lg text-center justify-center`}
                  >
                    Perfil
                  </div>
                )}
                {isAuthenticated && (
                  <div
                    onClick={handleLogout}
                    className={`w-auto px-2 bg-neutral-900 border-2 border-transparent hover:${getCurrentBorderColor()} active:bg-neutral-800 pt-1.5 h-10 text-white text-md cursor-pointer rounded-lg text-center justify-center`}
                  >
                    Sign out
                  </div>
                )}
                {!isAuthenticated && (
                  <>
                    <div
                      onClick={routeLogin}
                      className={`w-auto px-2 bg-neutral-900 border-2 border-transparent hover:${getCurrentBorderColor()} active:bg-neutral-800 pt-1.5 h-10 text-white text-md cursor-pointer rounded-lg text-center justify-center`}
                    >
                      Login
                    </div>
                    <div
                      onClick={routeRegister}
                      className={`w-auto px-2 bg-neutral-900 border-2 border-transparent hover:${getCurrentBorderColor()} active:bg-neutral-800 pt-1.5 h-10 text-white text-md cursor-pointer rounded-lg text-center justify-center`}
                    >
                      Register
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
