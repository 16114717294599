import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import GlobalRanking from '../components/GlobalRanking';
import MatchCard from '../components/MatchCard';
import UpcomingMatch from '../components/UpcomingMatch';
import RecentGames from '../components/RecentGames';

function Home() {
  const isBodyJunction = useMediaQuery({ query: '(max-width: 1005px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const [selectedLeague, setSelectedLeague] = useState(null);

  const handleLeagueSelection = () => {
    setSelectedLeague('Global Ranking');
  };

  const location = useLocation();

  const getCurrentBorderColor = () => {
    switch (location.pathname) {
      case '/Ligas':
        return 'border-lime-500';
      case '/Usuarios':
        return 'border-cyan-500';
      case '/Jogador':
        return 'border-white';
      case '/':
        return 'border-yellow-500';
    }
  };

  return (
    <div
      className={`relative w-full bg-transparent transition-transform duration-500 ease-in-out transform ${
        isBodyJunction ? 'translate-y-0' : 'translate-y-10 mb-16'
      }`}
    >
      <div className='flex justify-center'>
        <div className='bg-neutral-600 w-full shadow-xl pb-6' style={{ maxWidth: '1005px' }}>
          <div
            className={`w-full ${getCurrentBorderColor()} border-b-8 flex flex-row gap-5 text-white text-2xl font-bold py-3 pl-6 bg-neutral-900 items-center`}
          >
            <div className='flex-col'>
              <Icon icon='entypo:home' width='2.5rem' height='2.5rem' style={{ color: 'white' }} />
            </div>
            <div className='flex-col items-center content-center justify-center'>Home</div>
          </div>

          {!isSmallScreen && (
            <div className='object-contain flex-grow flex-shrink rounded-3xl flex items-start justify-start pl-10 pt-6 pb-5'>
              <img
                className='select-none h-full w-full flex-grow flex-shrink object-cover rounded-2xl cursor-pointer'
                style={{ maxWidth: '275px', height: 'auto' }}
                src='image/PoolLogo.svg'
                alt='5'
              />
            </div>
          )}

          {isSmallScreen && !isExtraSmallScreen && (
            <>
              <div className='flex p-5 flex-grow gap-1 items-center justify-center'>
                <div className='flex flex-row bg-neutral-900 border-2 border-yellow-500 rounded-xl flex-grow gap-4 items-center justify-center'>
                  <MatchCard />
                </div>
              </div>

              <div className='grid grid-cols-2 p-5 flex-grow gap-6 items-center justify-center mb-8'>
                <div
                  className={`w-full h-80 border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
                >
                  <UpcomingMatch />
                </div>
                <div
                  className={`w-full h-80 border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
                >
                  <RecentGames />
                </div>
              </div>
            </>
          )}

          {/**
          {!isSmallScreen && !isExtraSmallScreen && (
            
            <div className='flex collums-3 flex-grow gap-1 items-center justify-center'>
              <div
                className={`flex items-center justify-center rounded-r-xl border-y-2 border-r-2 ${getCurrentBorderColor()} bg-neutral-800`}
              >
                <RecentGames />
              </div>
              <div className='flex flex-grow items-center justify-center'>
                <MatchCard />
              </div>
              <div
                className={`flex items-center justify-center rounded-l-xl border-y-2 border-l-2 ${getCurrentBorderColor()} bg-neutral-800`}
              >
                <UpcomingMatch />
              </div>
            </div>

          )}
          */}

          {!isSmallScreen && !isExtraSmallScreen && (
            <div className='w-full grid gap-5 p-4 grid-cols-3 auto-rows-[320px]'>
              <div
                className={`row-span-2 col-span-2 border-2 rounded-xl bg-neutral-900 ${getCurrentBorderColor()}`}
              >
                <MatchCard />
              </div>
              <div
                className={`w-full h-full border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
              >
                <UpcomingMatch />
              </div>
              <div
                className={`w-full h-full border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
              >
                <RecentGames />
              </div>
            </div>
          )}

          {isExtraSmallScreen && (
            <div className='grid grid-cols-1 p-5 flex-grow gap-5 pb-4 items-center justify-center'>
              <div className='flex flex-row bg-neutral-900 border-2 border-yellow-500 rounded-xl flex-grow gap-4 items-center justify-center'>
                <MatchCard />
              </div>
              <div
                className={`w-full h-80 border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
              >
                <UpcomingMatch />
              </div>
              <div
                className={`w-full h-80 border-2 rounded-xl bg-neutral-800 ${getCurrentBorderColor()}`}
              >
                <RecentGames />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
