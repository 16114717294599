import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../api';
import { useMediaQuery } from 'react-responsive';
import { Icon } from '@iconify/react';
import userIcon from '@iconify-icons/fa-solid/user';
import { Flag } from 'semantic-ui-react';
import LeagueTable from '../components/LeagueTable';
import UpcomingMatchBox from '../components/UpcomingMatchBox';
import RecentMatchBox from '../components/RecentMatchBox';
import { useSelector } from 'react-redux';

function Perfil() {
  const { userid } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recentGames, setRecentGames] = useState([]);
  const [selectedLeagueId, setSelectedLeagueId] = useState(null);
  const isBodyJunction = useMediaQuery({ query: '(max-width: 1005px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 900px)' });
  const isExtraSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });

  const currentUser = useSelector((state) => state.auth.userInfo);
  const currentUserId = currentUser.id;

  useEffect(() => {
    fetchUser();
    fetchRecentGames();
  }, [userid]);

  const fetchUser = async () => {
    try {
      const response = await api.get(`/user/details/${userid}`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecentGames = async () => {
    try {
      const response = await api.get('/games/recent');
      setRecentGames(response.data);
    } catch (error) {
      console.error('Error fetching recent games:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user) {
    return <div>User data could not be loaded</div>;
  }

  const league =
    (user.leagues && user.leagues.find((league) => league.id === selectedLeagueId)) ||
    user.leagues[0];

  return (
    <div
      className={`w-full bg-transparent transition-transform duration-500 ease-in-out transform ${
        isBodyJunction ? 'translate-y-0' : 'translate-y-10 mb-16'
      }`}
    >
      <div className='flex justify-center'>
        <div
          className='bg-neutral-600 w-full shadow-xl h-full'
          style={{ maxWidth: '1005px', minHeight: '810px' }}
        >
          <div
            className={`w-full border-white border-b-8 flex flex-row gap-5 text-white text-2xl font-bold py-4 pl-6 bg-neutral-900 items-center`}
          >
            <div className='flex-col'>
              <Icon icon={userIcon} width='2.3rem' height='2.3rem' style={{ color: 'white' }} />
            </div>
            <div className='flex-col items-center content-center justify-center'>
              Informação de Jogador
            </div>
          </div>

          <div className='w-full h-full p-5 bg-neutral-700'>
            {!isSmallScreen && !isExtraSmallScreen && (
              <>
                <div className='flex flex-row gap-5 bg-neutral-700'>
                  <div>
                    <div className='w-32 h-32 flex-col rounded-full flex justify-center items-center bg-neutral-900'>
                      <img
                        className='w-28 h-28 rounded-full object-cover'
                        src={`https://api.ligas.purplews.live/photos/${user.Photo}`}
                        alt={user.id}
                      />
                    </div>
                  </div>
                  <div className='flex w-full h-full flex-row justify-between'>
                    <div className='flex flex-col justify-center h-40'>
                      <div className='text-white font-bold text-xl'>
                        {user.name} <Flag name={user.Nacionality} />
                      </div>
                      <div className='text-white text-sm flex items-center'>
                        {user.Age} anos
                        {user.id === currentUserId && (
                          <Link to={`/Settings/${currentUserId}`}>
                            <Icon
                              icon='mdi:pencil'
                              className='ml-2'
                              width='17'
                              height='17'
                              style={{ color: '#999999' }}
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col h-32 items-start justify-start'>
                      <div className='text-white font-bold text-xl p-4'>
                        {league ? league.Name : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-neutral-800 rounded-xl border-2 border-white'>
                  <table className='table-auto w-full'>
                    <thead>
                      <tr className='select-none text-white'>
                        <th className='px-4 py-3 w-12'>Partidas Disputadas</th>
                        <th className='px-4 py-3 w-12'>Partidas Ganhas</th>
                        <th className='px-4 py-3 w-12'>Partidas Perdidas</th>
                        <th className='px-4 py-3 w-12'>Rácio de Vitorias</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='text-white font-bold'>
                        <td className='px-4 py-3 text-center'>{user.Jogos}</td>
                        <td className='px-4 py-3 text-center'>{user.W}</td>
                        <td className='px-4 py-3 text-center'>{user.L}</td>
                        <td className='px-4 py-3 text-center'>{user.winRatioGeral}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='bg-neutral-800 rounded-xl mt-5 border-2 border-white'>
                  <div className='flex flex-col flex-wrap gap-2 p-5'>
                    <div className='flex flex-row text-white text-2xl font-bold'>Ligas</div>
                    <div className='flex flex-row gap-2'>
                      {user.leagues &&
                        user.leagues.map((league) => (
                          <div
                            key={league.id}
                            onClick={() => setSelectedLeagueId(league.id)}
                            className={`px-4 py-2 rounded cursor-pointer ${
                              league.id === selectedLeagueId
                                ? 'bg-neutral-500 text-white'
                                : 'bg-neutral-700 text-white'
                            }`}
                          >
                            {league.Name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className='bg-neutral-800 my-5 border-2 border-white rounded-xl overflow-y-auto col-span-2'>
                  <LeagueTable selectedLeague={league} />
                </div>
                <div className='grid grid-cols-2 auto-rows-[310px] gap-5'>
                  <div className='flex bg-neutral-800 border-2 border-white rounded-xl col-span-2 row-span-2 justify-center items-center'>
                    <RecentMatchBox userid={userid} chartData={recentGames} />
                  </div>
                  <div className='flex bg-neutral-800 border-2 border-white rounded-xl col-span-2 row-span-2 justify-center items-center'>
                    <UpcomingMatchBox userid={userid} chartData={recentGames} />
                  </div>
                </div>
              </>
            )}

            {isSmallScreen && !isExtraSmallScreen && (
              <>
                <div className='flex flex-row gap-5 bg-neutral-700'>
                  <div>
                    <div className='w-32 h-32 flex-col rounded-full flex justify-center items-center bg-neutral-900'>
                      <img
                        className='w-28 h-28 rounded-full object-cover'
                        src={`https://api.ligas.purplews.live/photos/${user.Photo}`}
                        alt={user.id}
                      />
                    </div>
                  </div>
                  <div className='flex w-full h-full flex-row justify-between'>
                    <div className='flex flex-col justify-center h-40'>
                      <div className='text-white font-bold text-xl'>
                        {user.name} <Flag name={user.Nacionality} />
                      </div>
                      <div className='text-white text-sm flex items-center'>
                        {user.Age} anos
                        {user.id === currentUserId && (
                          <Link to={`/Settings/${currentUserId}`}>
                            <Icon icon={userIcon} className='ml-2' style={{ color: '#999999' }} />
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col h-32 items-start justify-start'>
                      <div className='text-white font-bold text-xl p-4'>
                        {league ? league.Name : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-neutral-800 rounded-xl border-2 border-white'>
                  <table className='table-auto w-full'>
                    <thead>
                      <tr className='select-none text-white'>
                        <th className='px-4 py-3 w-12'>Partidas Disputadas</th>
                        <th className='px-4 py-3 w-12'>Partidas Ganhas</th>
                        <th className='px-4 py-3 w-12'>Partidas Perdidas</th>
                        <th className='px-4 py-3 w-12'>Rácio de Vitorias</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='text-white font-bold'>
                        <td className='px-4 py-3 text-center'>{user.Jogos}</td>
                        <td className='px-4 py-3 text-center'>{user.W}</td>
                        <td className='px-4 py-3 text-center'>{user.L}</td>
                        <td className='px-4 py-3 text-center'>{user.winRatioGeral}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='bg-neutral-800 rounded-xl mt-5 border-2 border-white'>
                  <div className='flex flex-col flex-wrap gap-2 p-5'>
                    <div className='flex flex-row text-white text-2xl font-bold'>Ligas</div>
                    <div className='flex flex-row gap-2'>
                      {user.leagues &&
                        user.leagues.map((league) => (
                          <div
                            key={league.id}
                            onClick={() => setSelectedLeagueId(league.id)}
                            className={`px-4 py-2 rounded cursor-pointer ${
                              league.id === selectedLeagueId
                                ? 'bg-neutral-500 text-white'
                                : 'bg-neutral-700 text-white'
                            }`}
                          >
                            {league.Name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className='bg-neutral-800 my-5 border-2 border-white rounded-xl overflow-y-auto col-span-2'>
                  <LeagueTable selectedLeague={league} />
                </div>
                <div className='grid grid-cols-2 auto-rows-[310px] gap-5'>
                  <div className='bg-neutral-800 border-2 border-white rounded-xl col-span-2 row-span-2'>
                    <RecentMatchBox userid={userid} chartData={recentGames} />
                  </div>
                  <div className='bg-neutral-800 border-2 border-white rounded-xl col-span-2 row-span-2'>
                    <UpcomingMatchBox userid={userid} chartData={recentGames} />
                  </div>
                </div>
              </>
            )}

            {isExtraSmallScreen && (
              <>
                <div className='flex flex-row gap-5 bg-neutral-700'>
                  <div>
                    <div className='w-32 h-32 flex-col rounded-full flex justify-center items-center bg-neutral-900'>
                      <img
                        className='w-28 h-28 rounded-full object-cover'
                        src={`https://api.ligas.purplews.live/photos/${user.Photo}`}
                        alt={user.id}
                      />
                    </div>
                  </div>
                  <div className='flex w-full h-full flex-row justify-between'>
                    <div className='flex flex-col justify-center h-40'>
                      <div className='text-white font-bold text-xl'>
                        {user.name} <Flag name={user.Nacionality} />
                      </div>
                      <div className='text-white text-sm flex items-center'>
                        {user.Age} anos
                        {user.id === currentUserId && (
                          <Link to={`/Settings/${currentUserId}`}>
                            <Icon icon={userIcon} className='ml-2' style={{ color: '#999999' }} />
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col h-32 items-start justify-start'>
                      <div className='text-white font-bold text-xl p-4'>
                        {league ? league.Name : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bg-neutral-800 rounded-xl border-2 border-white'>
                  <table className='table-auto w-full'>
                    <thead>
                      <tr className='select-none text-white'>
                        <th className='px-4 py-3 w-12'>Partidas Disputadas</th>
                        <th className='px-4 py-3 w-12'>Partidas Ganhas</th>
                        <th className='px-4 py-3 w-12'>Partidas Perdidas</th>
                        <th className='px-4 py-3 w-12'>Rácio de Vitorias</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='text-white font-bold'>
                        <td className='px-4 py-3 text-center'>{user.Jogos}</td>
                        <td className='px-4 py-3 text-center'>{user.W}</td>
                        <td className='px-4 py-3 text-center'>{user.L}</td>
                        <td className='px-4 py-3 text-center'>{user.winRatioGeral}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='bg-neutral-800 rounded-xl mt-5 border-2 border-white'>
                  <div className='flex flex-col flex-wrap gap-2 p-5'>
                    <div className='flex flex-row text-white text-2xl font-bold'>Ligas</div>
                    <div className='flex flex-row gap-2'>
                      {user.leagues &&
                        user.leagues.map((league) => (
                          <div
                            key={league.id}
                            onClick={() => setSelectedLeagueId(league.id)}
                            className={`px-4 py-2 rounded cursor-pointer ${
                              league.id === selectedLeagueId
                                ? 'bg-neutral-500 text-white'
                                : 'bg-neutral-700 text-white'
                            }`}
                          >
                            {league.Name}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className='bg-neutral-800 my-5 border-2 border-white rounded-xl overflow-y-auto col-span-2'>
                  <LeagueTable selectedLeague={league} />
                </div>
                <div className='grid grid-cols-2 auto-rows-[310px] gap-5'>
                  <div className='bg-neutral-800 border-2 border-white rounded-xl col-span-2 row-span-2'>
                    <RecentMatchBox userid={userid} chartData={recentGames} />
                  </div>
                  <div className='bg-neutral-800 border-2 border-white rounded-xl col-span-2 row-span-2'>
                    <UpcomingMatchBox userid={userid} chartData={recentGames} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Perfil;
